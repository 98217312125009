export const environment = {
    production: false,
    config:{
        clientId: 'fc0f18e9-0f6c-4365-a9b9-ef264f1128ae',
        authority: 'https://login.microsoftonline.com/11491073-990d-433d-bd35-3b3be0bfba26'
    },
    apiUrl: "https://dev-databroker-adminapi.azurewebsites.net",
    apiScope: "api://f85e9031-b1ab-484b-bd02-aaadcfa712ef/access_as_user",
    appInsights:{
        instrumentationKey: "bb027e3f-1a45-47e3-9274-eab472830466"
    }
};
